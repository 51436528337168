import React from "react"
import { AnimatePresence, motion } from "framer-motion"
import SvgIcon from "@mui/material/SvgIcon"
import ExperienceInfo from "./ExperienceInfo"

export default function ExperienceDetails({
  selectedExperience,
  setSelectedExperience,
  mode,
}) {
  const modalVariants = {
    hidden: { opacity: 0, y: -50 },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: 50 },
  }
  const backdropVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
    exit: { opacity: 0 },
  }

  return (
    <AnimatePresence>
      {selectedExperience && (
        <motion.div
          variants={backdropVariants}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
          onClick={() => setSelectedExperience(null)}
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(27, 41, 75, 0.5)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 1000,
          }}
        >
          <motion.div
            variants={modalVariants}
            onClick={(e) => e.stopPropagation()}
            layoutId={selectedExperience?.id}
            style={{
              background: mode === "dark" ? "#e0e0e0" : "#ebebeb",
              borderRadius: "15px",
              color: "#1f1f1f",
              padding: "15px",
              maxWidth: "95%",
              maxHeight: "95%",
              overflow: "auto",
              position: "relative",
            }}
            transition={{
              type: "spring",
              stiffness: 300,
              damping: 30,
              duration: 0.3,
            }}
          >
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.1, duration: 0.3 }}
              style={{ paddingRight: 2 }}
            >
              <ExperienceInfo
                selectedExperience={selectedExperience}
                mode={mode}
              />
            </motion.div>
            <motion.button
              onClick={() => setSelectedExperience(null)}
              whileHover={{ scale: 1.2 }}
              whileTap={{ scale: 1.2 }}
              transition={{ delay: 0.1, duration: 0.1 }}
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                border: "none",
                background:
                  mode === "dark"
                    ? "rgba(94, 234, 212, .4)"
                    : "rgba(255, 87, 51, .4)",
                color: "rgb(31, 31, 31)",
                cursor: "pointer",
                outline: "none",
                transition: "background .2s",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 1002,
              }}
            >
              <SvgIcon>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </SvgIcon>
            </motion.button>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
