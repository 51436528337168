import Link from "@mui/material/Link"
import SvgIcon from "@mui/material/SvgIcon"
import { styled } from "@mui/system"
import { Typography } from "@mui/material"

const ArrowIcon = styled(SvgIcon)(() => ({
  transition: "transform 0s ease",
  fontSize: "0.9rem",
}))

const CustomLink = ({
  href,
  children,
  hoverColor,
  linkColor,
  variant = "caption",
  fontWeight = 600,
  showIcon = true,
  textWrap="nowrap",
  ...props
}) => {
  return (
    <Link
      href={href}
      underline="none"
      target="_blank"
      rel="noopener noreferrer"
      {...props}
      sx={{
        display: "inline-flex",
        alignItems: "flex-end",
        color: linkColor,
        textDecoration: "none",
        ".arrowIcon": { transform: "translateY(2px) translateX(-2px)", },
        "&:hover": {
          color: hoverColor,
        },
        "&:hover .arrowIcon": {
          transform: "translateY(-2px) translateX(2px)",
          transition: "transform 0.2s ease",
        },
      }}
    >
      <Typography variant={variant} sx={{ fontWeight: fontWeight, textWrap: textWrap }}>
        {children} {" "}
        {showIcon && 
        <>
          <ArrowIcon className="arrowIcon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m4.5 19.5 15-15m0 0H8.25m11.25 0v11.25"
              />
            </svg>
          </ArrowIcon>
          {" "}
          </>
        }
      </Typography>
    </Link>
  )
}

export default CustomLink
