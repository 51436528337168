import { useCallback, useState } from "react"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import Card from "@mui/material/Card"
import SvgIcon from "@mui/material/SvgIcon"
import { education } from ".././data/education"
import CustomLink from "../utils/CustomLink"
import Grid from "@mui/material/Grid2"
import { SlideIn } from "../utils/ExampleUsage"
import { CardActionArea } from "@mui/material"

const EduBox = ({ mode }) => {
  const [focusedCard, setFocusedCard] = useState(null)

  const handleMouseEnter = useCallback((index) => {
    setFocusedCard(index)
  }, [])

  const handleMouseLeave = useCallback(() => {
    setFocusedCard(null)
  }, [])

  return (
    <SlideIn direction="right">
    <Stack
      direction="column"
      spacing={3}
      sx={{
        justifyContent: "flex-start",
        alignItems: "flex-start",
        width: "100%",
      }}
    >
      <Typography
        variant="h6"
        sx={{
          fontWeight: 700,
          textTransform: "uppercase",
          color: mode === "dark" ? "#fff" : "#000",
          display: { lg: "none", xs: "block" },
          paddingBottom: 6,
        }}
      >
        Education
      </Typography>
      {education.map((edu, index) => (
        <Card
          key={index}
          onMouseEnter={() => handleMouseEnter(index)}
          onMouseLeave={handleMouseLeave}
          variant="outlined"
          sx={{
            minWidth: "100%",
            backgroundColor: "transparent",
            border: `0px solid transparent`,
            backgroundImage: "none",
            borderRadius: "10px",
            transition: "all 0.3s ease",
            color: mode === "dark" ? "#94A3B8" : "#727783",
            opacity: focusedCard === null || focusedCard === index ? 1 : 0.2,
            boxShadow: "rgba(0, 0, 0, 0) 0px 5px 5px",
            "&:hover": {
              backgroundColor: "transparent",
              backgroundImage: mode === "dark" ? "#0f172a" : "#5c5c5c",
              borderRadius: "10px",
              boxShadow: mode === "dark" ? "rgba(0, 0, 0, 0.35) 0px 5px 5px" : "rgba(184, 184, 184, 0.35) 0px 5px 5px",
              border: `0.1px solid ${
                mode === "dark"
                  ? "rgba(51, 51, 51, 0.5)"
                  : "rgba(114, 119, 131, 0.5)"
              }`,
            },
          }}
        >
          <CardActionArea sx={{ padding: 2, cursor: 'default' }}>
            <Stack
              direction= {{sm: 'row', xs: 'column'}}
              spacing={{sm: 8, xs: 4}}
              sx={{
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              <Stack
                direction="column"
                spacing={2}
                sx={{
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: {sm: "7px", xs: "5px"},
                  backgroundColor: mode === "dark" ? "#f5f5f5" : "#e0e0e0",
                  padding: {sm: 2, xs: 1},
                }}
              >
                {edu?.logo && (
                  <Grid
                    size={"grow"}
                    sx={{
                      display: "flex",
                      maxWidth: "10rem",
                      width: "100%",
                    }}
                  >
                    <img
                      alt="logo"
                      src={edu?.logo}
                      style={{
                        width: "10rem",
                        height: "auto",
                      }}
                      loading="lazy"
                    />
                  </Grid>
                )}
              </Stack>
              <Stack
                direction={"column"}
                spacing={1}
                alignItems="flex-start"
              >
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  sx={{ textWrap: "nowrap" }}
                >
                  <Typography
                    variant="caption"
                    sx={{
                      color: mode === "dark" ? "#94A3B8" : "#0c120c",
                      textTransform: "uppercase",
                    }}
                  >
                    {edu?.start}
                  </Typography>
                  <div
                    className="ldash"
                    style={{
                      width: "8px",
                      height: "1px",
                      backgroundColor: mode === "dark" ? "#94A3B8" : "#0c120c",
                      padding: "0 8px",
                    }}
                  ></div>
                  <Typography
                    variant="caption"
                    sx={{
                      color: mode === "dark" ? "#94A3B8" : "#0c120c",
                      textTransform: "uppercase",
                    }}
                  >
                    {edu?.end}
                  </Typography>
                </Stack>
                <Typography
                  variant="h7"
                  color={mode === "dark" ? "#fff" : "#000"}
                  sx={{
                    fontWeight: 700,
                  }}
                >
                  {edu?.department}, {edu?.degree}
                </Typography>

                <CustomLink
                  href={edu?.link}
                  color={mode === "dark" ? "#5EEAD4" : "#ff5733"}
                  variant="h7"
                >
                  {edu?.university}
                </CustomLink>
                <Stack
                  direction="row"
                  spacing={1}
                  sx={{
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <SvgIcon fontSize="small">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="size-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9 6.75V15m6-6v8.25m.503 3.498 4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 0 0-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0Z"
                      />
                    </svg>
                  </SvgIcon>
                  <Typography
                    variant="edu"
                    sx={{
                      color: mode === "dark" ? "#94A3B8" : "#0c120c",
                    }}
                  >
                    {edu?.location}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </CardActionArea>
        </Card>
      ))}
    </Stack>
    </SlideIn>
  )
}

export default EduBox
