import { Box, Stack, Typography } from "@mui/material"
import { lazy, Suspense, useState } from "react"
import {
  Frontend_skill,
  Backend_skill,
  standards,
  communication_skill,
  problem_solving_skill,
  learning_skill,
  language_skills,
} from "../data/skills"
import { SlideIn } from "../utils/ExampleUsage"

const SkillCard = lazy(() => import("./SkillCard"))

const technicalSkills = [
  { name: "Frontend", skills: Frontend_skill },
  { name: "Backend", skills: Backend_skill },
  { name: "Standards", skills: standards },
]

const softSkills = [
  { name: "Communication Skills", skills: communication_skill },
  {
    name: "Problem-Solving and Critical Thinking",
    skills: problem_solving_skill,
  },
  { name: "Continuous Learning and Adaptability", skills: learning_skill },
]

const languageSkills = [{ name: "Language Skills", skills: language_skills }]

export default function Skills({ mode }) {
  const [focusedCard, setFocusedCard] = useState(null)
  return (
    <SlideIn direction="right">
      <Typography
        variant="h6"
        sx={{
          fontWeight: 700,
          textTransform: "uppercase",
          color: mode === "dark" ? "#fff" : "#000",
          display: { lg: "none", xs: "block" },
          paddingBottom: 6,
        }}
      >
        Skills and Expertise
      </Typography>
      <Box sx={{ flexGrow: 1 }}>
        <Typography
          variant="edu"
          sx={{
            fontWeight: 900,
            fontSize: { xs: "h7.fontSize", sm: "h6.fontSize" },
            textTransform: "uppercase",
            color: mode === "dark" ? "#c6c8d2" : "#5a5f72",
          }}
        >
          Technical Skills
        </Typography>
        <Stack
          spacing={{ xs: 3, sm: 4 }}
          direction="row"
          useFlexGap
          sx={{ flexWrap: "wrap", pt: 2 }}
        >
          <Suspense>
            {technicalSkills.map((category, index) => (
              <SkillCard
                mode={mode}
                category={category?.name}
                key={index}
                skills={category?.skills}
                focusedCard={focusedCard}
                setFocusedCard={setFocusedCard}
                index={`tech-${index}`}
              />
            ))}
          </Suspense>
        </Stack>
      </Box>
      <Box sx={{ flexGrow: 1, pt: 6 }}>
        <Typography
          variant="edu"
          sx={{
            fontWeight: 900,
            fontSize: { xs: "h7.fontSize", sm: "h6.fontSize" },
            textTransform: "uppercase",
            color: mode === "dark" ? "#c6c8d2" : "#5a5f72",
          }}
        >
          Soft Skills
        </Typography>
        <Stack
          spacing={{ xs: 1, sm: 3 }}
          direction="row"
          useFlexGap
          sx={{ flexWrap: "wrap", pt: 3 }}
        >
          <Suspense>
            {softSkills.map((category, index) => (
              <SkillCard
                mode={mode}
                category={category?.name}
                key={index}
                skills={category?.skills}
                focusedCard={focusedCard}
                setFocusedCard={setFocusedCard}
                index={`soft-${index}`}
              />
            ))}
          </Suspense>
        </Stack>
      </Box>
      <Box sx={{ flexGrow: 1, pt: 6 }}>
        <Typography
          variant="edu"
          sx={{
            fontWeight: 900,
            fontSize: { xs: "h7.fontSize", sm: "h6.fontSize" },
            textTransform: "uppercase",
            color: mode === "dark" ? "#c6c8d2" : "#5a5f72",
          }}
        >
          Language Skills
        </Typography>
        <Stack
          spacing={{ xs: 1, sm: 3 }}
          direction="row"
          useFlexGap
          sx={{ flexWrap: "wrap", pt: 3 }}
        >
          <Suspense>
            {languageSkills.map((category, index) => (
              <SkillCard
                mode={mode}
                category={category?.name}
                key={index}
                skills={category?.skills}
                focusedCard={focusedCard}
                setFocusedCard={setFocusedCard}
                index={`lang-${index}`}
              />
            ))}
          </Suspense>
        </Stack>
      </Box>
    </SlideIn>
  )
}
