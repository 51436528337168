import React, { useState } from "react"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid2"
import Stack from "@mui/material/Stack"
import Chip from "@mui/material/Chip"
import CustomLink from "../utils/CustomLink"
import Divider from "@mui/material/Divider"
import useMediaQuery from "@mui/material/useMediaQuery"
import { CheckmarkIcon } from "../utils/CheckmarkIcon"
import { ProjectLink } from "../utils/ProjectLink"
import Project from "./Project"

export function LongTextComponent({ longDescription, mode }) {
  const [isExpanded, setIsExpanded] = useState(false)

  const toggleExpand = () => {
    setIsExpanded(!isExpanded)
  }

  const shortDescription = longDescription?.slice(0, 300)
  const paragraphs = isExpanded
    ? longDescription.split("\n\n")
    : `${shortDescription}...`.split("\n\n")

  return (
    <Box sx={{ padding: "5px", maxWidth: "100%", marginX: { lg: 4, xs: 0 } }}>
      {paragraphs.map((paragraph, index) => (
        <Typography
          key={index}
          variant="body1"
          sx={{
            marginBottom: "16px",
            lineHeight: 1.6,
            textAlign: "justify",
            textJustify: "inter-word",
          }}
        >
          {paragraph}
        </Typography>
      ))}
      <CustomLink
        onClick={toggleExpand}
        variant="contained"
        linkColor={mode === "dark" ? "#107e6e" : "#ff5733"}
        sx={{ marginTop: "15px" }}
        showIcon={false}
      >
        {isExpanded ? "Read Less" : "Read More"}
      </CustomLink>
    </Box>
  )
}

export default function ExperienceInfo({ selectedExperience, mode }) {
  const matches = useMediaQuery("(min-width:800px)")
  return (
    <Box sx={{ flexGrow: 1, width: "100%" }}>
      <Grid container spacing={1} columns={16}>
        <Grid
          item
          size={16}
          sx={{
            display: "flex",
          }}
        >
          <Stack
            direction="row"
            spacing={4}
            sx={{
              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}
          >
            <Grid
              size={4}
              sx={{
                display: "flex",
                maxWidth: "4rem",
                width: "auto",
              }}
            >
              <img
                alt="logo"
                src={selectedExperience?.logo}
                style={{
                  width: "100%",
                  height: "auto",
                }}
                loading="lazy"
              />
            </Grid>
            <Grid
              size={"grow"}
              sx={{
                display: "flex",
              }}
            >
              <Stack
                direction="column"
                spacing={0}
                sx={{
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: 700,
                    textWrap: { sm: "nowrap", xs: "wrap" },
                    paddingRight: { sm: 1, xs: 5 },
                  }}
                >
                  {selectedExperience?.title}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: 300,
                    textWrap: { sm: "nowrap", xs: "flexWrap" },
                    paddingBottom: 0.5,
                  }}
                >
                  {selectedExperience?.Company}
                </Typography>
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  sx={{ textWrap: "nowrap" }}
                >
                  <Typography
                    variant="caption"
                    sx={{
                      textTransform: "uppercase",
                    }}
                  >
                    {selectedExperience?.start}
                  </Typography>
                  <div
                    className="ldash"
                    style={{
                      width: "8px",
                      height: "1px",
                      backgroundColor: mode === "dark" ? "#94A3B8" : "#0c120c",
                      padding: "0 8px",
                    }}
                  ></div>
                  <Typography
                    variant="caption"
                    sx={{
                      textTransform: "uppercase",
                    }}
                  >
                    {selectedExperience?.end}
                  </Typography>
                </Stack>
                <Typography
                  variant="subtitle2"
                  sx={{
                    fontWeight: 300,
                    textWrap: { sm: "nowrap", xs: "flexWrap" },
                    paddingBottom: 1,
                  }}
                >
                  {selectedExperience?.location}
                </Typography>
              </Stack>
            </Grid>
          </Stack>
        </Grid>
        <Grid
          item
          sx={{
            display: "flex",
            paddingX: 0,
            justifyContent: "flex-start",
            textAlign: "left",
            flexDirection: "column",
          }}
          size={{ md: 10, xs: 16 }}
        >
          <LongTextComponent
            longDescription={selectedExperience?.longDescription}
            mode={mode}
          />
        </Grid>
        <Grid
          item
          sx={{ display: "block", paddingX: 0 }}
          size={{ md: 6, xs: 16 }}
        >
          <Stack direction="row" useFlexGap sx={{ flexWrap: "wrap", gap: 0.5 }}>
            {selectedExperience?.stack[0]?.split(", ").map((s, index) => (
              <Chip
                key={index}
                label={s}
                sx={{
                  color: mode === "dark" ? "#1f1f1f" : "#ff5733",
                  backgroundColor:
                    mode === "dark"
                      ? "rgba(26, 199, 173, 0.2)"
                      : "rgba(255, 190, 173, 0.2)",
                  fontWeight: 500,
                }}
              />
            ))}
          </Stack>
        </Grid>
        <Grid
          item
          sx={{
            display: "flex",
            paddingX: 0,
            justifyContent: "flex-start",
            textAlign: "left",
            flexDirection: "column",
            width: "100%",
            paddingTop: 2,
          }}
          size={16}
        >
          <Project
            fontWeight={700}
            text1={"Project"}
            text2={"Project Overview"}
            text3={"Link"}
          />
          {selectedExperience?.projectsDetails?.map((se, i) => (
            <Project
              key={`${se.project}-${i}`}
              fontWeight={300}
              color1={mode === "dark" ? "#107e6e" : "#ff5733"}
              text1={se?.project}
              text2={
                <Stack
                  spacing={0.5}
                  direction="column"
                  useFlexGap
                  sx={{ flexWrap: "wrap" }}
                >
                  {se?.projectOverview?.map((k, i) => (
                    <React.Fragment key={`${k}-${i}`}>
                      <Typography
                        variant="body2"
                        sx={{
                          fontWeight: 300,
                          display: "flex",
                        }}
                      >
                        <CheckmarkIcon mode={mode} />
                        {k}
                      </Typography>
                      {i < se?.projectOverview?.length - 1 && (
                        <Divider
                          variant="middle"
                          sx={{ borderColor: "#c2c2c2" }}
                        />
                      )}
                    </React.Fragment>
                  ))}
                </Stack>
              }
              text3={
                <ProjectLink
                  project={se?.project}
                  link={se?.link}
                  mode={mode}
                  matches={matches}
                />
              }
            />
          ))}
        </Grid>
      </Grid>
    </Box>
  )
}
