/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import '../../theme/theme.css'
import { SlideIn, StaggeredList } from  '.././utils/ExampleUsage'

export default function Sidebar({ mode }) {
  const [selected, setSelected] = useState(null)
  const menuItems = ['About', 'Experience', 'Publications', 'Education', 'Skills']

  const handleClick = (index, item) => {
    setSelected(index);
    const section = document.getElementById(item.toLowerCase())
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const handleScroll = () => {
    menuItems.forEach((item, index) => {
      const section = document.getElementById(item.toLowerCase())
      if (section) {
        const sectionTop = section.getBoundingClientRect().top
        const viewportHeight = window.innerHeight
        
        if (sectionTop >= 0 && sectionTop < viewportHeight * 0.5) {
          setSelected(index)
        }
      }
    })
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    handleScroll()
  }, [])

  return (
    <SlideIn>
      <Stack
        className="navbar"
        direction="column"
        spacing={1.5}
        sx={{
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          paddingTop: '50px'
        }}
      >
        <StaggeredList>
          {menuItems.map((item, index) => (
            <Typography
              key={index}
              className={`nav-item ${selected === index ? 'active' : ''}`}
              onClick={() => handleClick(index, item)}
              variant="button"
              sx={{ paddingY: 1 }}
            >
              <Link
                className="nav-text"
                sx={{
                  fontWeight: 600,
                  color:
                    selected === index
                      ? mode === 'dark'
                        ? '#ddd'
                        : '#ff5733'
                      : mode === 'dark'
                      ? '#94A3B8'
                      : '#0c120c',
                  ':hover': {
                    color:
                      selected === index
                        ? mode === 'dark'
                          ? '#ddd'
                          : '#ff5733'
                        : mode === 'dark'
                        ? '#ddd'
                        : '#ff5733'
                  }
                }}
                underline="none"
              >
                {item === 'Skills' ? 'Skills and Expertise' : item}
              </Link>
              <div
                className="dash"
                style={{
                  backgroundColor:
                    selected === index
                      ? mode === 'dark'
                        ? '#ddd'
                        : '#ff5733'
                      : mode === 'dark'
                      ? '#94A3B8'
                      : '#0c120c',
                  ':hover': {
                    color:
                      selected === index
                        ? mode === 'dark'
                          ? '#ddd'
                          : '#ff5733'
                        : mode === 'dark'
                        ? '#ddd'
                        : '#ff5733'
                  }
                }}
              ></div>
            </Typography>
          ))}
        </StaggeredList>
      </Stack>
    </SlideIn>
  );
}
