/* eslint-disable react/prop-types */
import { useState } from 'react'
import './theme/theme.css'
import Stack from '@mui/material/Stack'
import Cursor from './components/utils/Cursor'
import Info from './components/info/Info'
import Box from '@mui/material/Box'
import Mode from './components/utils/Mode'
import Container from '@mui/material/Container'
import Sidebar from './components/global/Sidebar'
import Contact from './components/info/Contact'
import AboutMe from './components/about/AboutMe'
import Experience from './components/experience/Experience'
import { motion, useScroll, useSpring } from "framer-motion"
import ExperienceDetails from './components/experience/ExperienceDetails'
import EduBox from './components/education/EduBox'
import StarCanvas from './components/stars/StarCanvas'
import Skills from './components/skills/Skills'
import Publications from './components/publications/Publications'

const App = ({ toggleTheme, mode }) => {
  const { scrollYProgress } = useScroll()
  const scaleX = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001
  })
  const [selectedExperience, setSelectedExperience] = useState(null)

  return (
    <>
    <motion.div 
      style={{ scaleX, backgroundColor: mode === 'dark' ? '#5EEAD4': '#ff5733', overflowX: 'hidden'}} 
      className="progress-bar"
    />
    <Box
      maxWidth="100%" 
      sx={{ 
        overflowX: 'hidden', 
        overflowY: 'auto', 
        minHeight: '100%', 
        paddingX: 0 
      }}
    >
      <Box 
        sx={{ 
          marginLeft: 'auto', 
          textAlign: 'end', 
          width: '95%', 
          position: 'fixed', 
          marginBottom: '10px',
          zIndex: 999
        }}
      >
        <Mode 
          toggleTheme={toggleTheme} 
          mode={mode} 
        />
      </Box>
      <Box 
        sx={{
          display: { sm: 'hidden', lg: 'block' }
        }}
      >
        <Cursor mode={mode}/>
      </Box>
      <Container 
        maxWidth={'lg'} 
        sx={{
          paddingTop: '5%',  
          minHeight: '100%',
          overflowX: 'hidden',  
          paddingX: { xs: '5px', sm: '16px' }, 
          maxWidth: '100%'
        }}
      >
        {mode === "dark" && <StarCanvas />}
        <Stack 
          direction={{ xs: 'column', lg: 'row' }}
          spacing={{ xs: 1, lg: 2 }}
          useFlexGap
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
            minHeight: '100vh',
            flexWrap: 'wrap',
            overflowY: 'hidden'
          }}
        > 
          <Stack
            direction="column"
            spacing={8}
            sx={{
              width: { xs: '100%', lg: '400px' },
              position: 'relative',
              flexShrink: 0
            }}
          >
            <Box sx={{ position: {lg: 'fixed', xs: 'relative'}, width: 'inherit'}}>
              <Info mode={mode}/>
              <Sidebar mode={mode}/>
              <Contact />
            </Box>
          </Stack>
          <Stack
            direction="column"
            spacing={18}
            sx={{
              flexGrow: 1,
              paddingLeft: { xs: 0, lg: '500px' },
              minHeight: '100vh',
              overflowY: 'hidden',
              overflowX: 'hidden',
              height: '100%',
              paddingTop: { xs: '100px', lg: 0 },
              paddingBottom: '100px'
            }}
          >
     
            <section id="about">
              <AboutMe mode={mode}/>
             </section>
             <section id="experience">
                <Experience 
                  mode={mode}  
                  setSelectedExperience={setSelectedExperience}
                  selectedExperience={selectedExperience}
                />
              </section>
              <section id="publications">
                <Publications mode={mode}/>
              </section>
              <section id="education">
                <EduBox mode={mode}/>
              </section>
              <section id="skills">
                <Skills mode={mode}/>
              </section>
             
          </Stack>
        </Stack>
      </Container>
    </Box>
    <ExperienceDetails 
      selectedExperience={selectedExperience}
      setSelectedExperience={setSelectedExperience}
      mode={mode}
    />
    </>
  )
}

export default App
