import InstagramIcon from '@mui/icons-material/Instagram'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail'
import Stack from '@mui/material/Stack'
import IconButton from '@mui/material/IconButton'
import { SlideIn } from  '.././utils/ExampleUsage'

export default function Contact () {
    
    return (
        <SlideIn direction='left'>
            <Stack
                direction="row"
                spacing={2}
                sx={{
                    justifyContent: "flex-start",
                    alignItems: "center",
                    paddingTop: {xs: '12vh', lg: '20vh'}
                }}
            >
                <IconButton aria-label="Instagram" href='https://www.instagram.com/fluffy.minicooper/' target="_blank"> 
                    <InstagramIcon />
                </IconButton >
                <IconButton aria-label="LinkedIn" href='https://de.linkedin.com/in/naguib-heiba' target="_blank"> 
                    <LinkedInIcon />
                </IconButton >
                <IconButton aria-label="Email" target="_blank" href='mailto:m.naguib.heiba@gmail.com'>
                    <AlternateEmailIcon />
                </IconButton>
            </Stack>
        </SlideIn>
    )
}