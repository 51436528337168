export const education = [
  {
    id: 1,
    start: "Sep 2024",
    end: "PRESENT",
    degree: "PhD.",
    department: "Computer Science",
    university: "RWTH Aachen University",
    location: "Aachen - Germany",
    link: "https://www.rwth-aachen.de/go/id/a/?lidx=1",
    logo: "./rwth.webp",
  },
  {
    id: 2,
    start: "Sep 2017",
    end: "Oct 2019",
    degree: "M.Eng.",
    department: "Industrial Informatics",
    university: "Hochschule Emden/Leer",
    location: "Emden - Germany",
    link: "https://www.hs-emden-leer.de/",
    logo: "./hsel.svg",
  },
  {
    id: 3,
    start: "Sep 2011",
    end: "Jun 2017",
    degree: "B.Sc.",
    department: "Electromechanics",
    university: "Alexandria University",
    location: "Alexandria - Egypt",
    link: "https://www.linkedin.com/company/faculty-of-engineering-alexandria-university-egypt/",
    logo: "./alex.webp",
  },
]
