import { useState, useEffect } from 'react'
import '../../theme/theme.css'

export default function Cursor({ mode }) {
  const [position, setPosition] = useState({ x: -100, y: -100 }) // start off screen
  const [mouseActive, setMouseActive] = useState(false)

  useEffect(() => {
    const handlePointerMove = (e) => {
      setPosition({
        x: e.clientX + window.scrollX,
        y: e.clientY + window.scrollY,
      })
      if (e.pointerType === 'mouse') {
        setMouseActive(true)
      }
    }
    const handlePointerDown = (e) => {
      if (e.pointerType !== 'mouse') {
        setMouseActive(false)
      }
    }
    window.addEventListener('pointermove', handlePointerMove)
    window.addEventListener('pointerdown', handlePointerDown)
    return () => {
      window.removeEventListener('pointermove', handlePointerMove)
      window.removeEventListener('pointerdown', handlePointerDown)
    }
  }, [])

  useEffect(() => {
    const timer = setTimeout(() => setMouseActive(false), 2000) // 2 seconds
    return () => clearTimeout(timer)
  }, [position])

  return (
    mouseActive && (
      <div
        className="cursorStyle"
        style={{
          top: position.y,
          left: position.x,
          background:
            mode === 'dark'
              ? 'radial-gradient(#24365ff7, #121b31f7)'
              : 'radial-gradient(#fff, #fef9ef)',
        }}
      ></div>
    )
  )
}