import React, { useState, useRef, Suspense, useEffect } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { Points, PointMaterial } from '@react-three/drei';

function getRandomSpherePoints(numPoints, radius) {
  let points = new Float32Array(numPoints * 3);
  for (let i = 0; i < numPoints; ++i) {
    let r = radius * Math.cbrt(Math.random());
    let theta = Math.random() * 2 * Math.PI;
    let phi = Math.acos(2 * Math.random() - 1);
    let x = r * Math.sin(phi) * Math.cos(theta);
    let y = r * Math.sin(phi) * Math.sin(theta);
    let z = r * Math.cos(phi);
    points[i * 3] = x;
    points[i * 3 + 1] = y;
    points[i * 3 + 2] = z;
  }
  return points;
}

const Stars = (props) => {
  const ref = useRef();
  const [sphere, setSphere] = useState(new Float32Array(5000 * 3));

  useEffect(() => {
    setSphere(getRandomSpherePoints(5000, 5));
  }, []);
  useFrame((state, delta) => {
    ref.current.rotation.x -= delta / 50;
    ref.current.rotation.y -= delta / 75;
  })

  return (
    <group rotation={[0, 0, Math.PI / 4]}>
      <Points ref={ref} positions={sphere} {...props}>
        <PointMaterial
          transparent
          color="#fff"
          size={0.005}
          sizeAttenuation={true}
          depthWrite={false}
          opacity={0.5} 
        />
      </Points>
    </group>
  );
};

const StarCanvas = () => (
  <Canvas style={{ position: 'fixed', top: 0, left: 0, width: '100vw', height: '100vh', zIndex: -1 }}>
    <Suspense fallback={null}>
      <Stars />
    </Suspense>
  </Canvas>
);

export default StarCanvas;