/* eslint-disable react/prop-types */
import React, { useState, memo, useCallback, useEffect } from "react"
import Typography from "@mui/material/Typography"
import Stack from "@mui/material/Stack"
import Card from "@mui/material/Card"
import CardActionArea from "@mui/material/CardActionArea"
import Link from "@mui/material/Link"
import Chip from "@mui/material/Chip"
import { AnimatePresence, motion } from "framer-motion"
import { SlideIn } from "../utils/ExampleUsage"
import { SvgIcon } from "@mui/material"
import { experience } from "../data/experience"
import CustomLink from "../utils/CustomLink"

const cardVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      stiffness: 300,
      damping: 30,
      duration: 0.3,
    },
  },
  hover: {
    scale: 1.03,
    boxShadow: "0px 5px 15px rgba(0,0,0,0.1)",
    transition: {
      type: "spring",
      stiffness: 400,
      damping: 10,
    },
  },
  tap: { scale: 0.98 },
  exit: {
    opacity: 0,
    scale: 0.95,
    transition: {
      duration: 0.2,
    },
  },
}

const ExperienceCard = memo(function ExperienceCard({
  e,
  i,
  focusedCard,
  setFocusedCard,
  mode,
  setSelectedExperience,
  selectedExperience,
}) {
  const handleMouseEnter = useCallback(
    () => setFocusedCard(i),
    [i, setFocusedCard]
  )
  const handleMouseLeave = useCallback(
    () => setFocusedCard(null),
    [setFocusedCard]
  )
  const handleClick = useCallback(() => {
    if (!selectedExperience) setSelectedExperience(e)
  }, [e, selectedExperience, setSelectedExperience])

  return (
    <motion.div
      key={e.id}
      layoutId={e.id}
      variants={cardVariants}
      initial="hidden"
      animate="visible"
      whileHover="hover"
      whileTap="tap"
      exit="exit"
      onClick={handleClick}
      style={{
        backgroundColor: "transparent",
        borderRadius: "15px",
      }}
    >
      <Card
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        sx={{
          minWidth: { xs: "100%", sm: "300px", md: "400px" },
          backgroundColor: "transparent",
          boxShadow: "none",
          border: "none",
          zIndex: "998",
          backgroundImage: "none",
          borderRadius: "15px",
          transition: "all 0.3s ease",
          color: mode === "dark" ? "#94A3B8" : "#727783",
          opacity: focusedCard === null || focusedCard === i ? 1 : 0.2,
          "&:hover": {
            backgroundColor: "transparent",
            backgroundImage: mode === "dark" ? "#0f172a" : "#5c5c5c",
            borderRadius: "10px",
          },
        }}
      >
        <CardActionArea sx={{ padding: 3 }}>
          <Stack
            direction={{ lg: "row", xs: "column" }}
            spacing={{ lg: 2, xs: 2 }}
            alignItems="flex-start"
          >
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              sx={{ textWrap: "nowrap" }}
            >
              <Typography
                variant="caption"
                sx={{
                  color: mode === "dark" ? "#94A3B8" : "#0c120c",
                  textTransform: "uppercase",
                }}
              >
                {e?.start}
              </Typography>
              <div
                className="ldash"
                style={{
                  width: "8px",
                  height: "1px",
                  backgroundColor: mode === "dark" ? "#94A3B8" : "#0c120c",
                  padding: "0 8px",
                }}
              ></div>
              <Typography
                variant="caption"
                sx={{
                  color: mode === "dark" ? "#94A3B8" : "#0c120c",
                  textTransform: "uppercase",
                }}
              >
                {e?.end}
              </Typography>
            </Stack>

            <Stack 
              direction="column" 
              spacing={2}
              sx={{
                justifyContent: "center",
                alignItems: "flex-start",
              }}
              >
              <CustomLink 
                showIcon={false} 
                variant="h7"
                color= {mode === "dark" ? "#5EEAD4" : "#ff5733"}
                sx={{ fontWeight: 700, justifyContent: "center",
                  alignItems: "flex-start"}}
              >
                {e?.title}
              </CustomLink>
              <CustomLink 
                showIcon={false} 
                  variant="edu"
                  color= {mode === "dark" ? "#ddd" : "#000"}
                  sx={{ fontWeight: 900}}
              >
                {e?.Company}
              </CustomLink>
              <Typography variant="body1">{e?.shortDescription}</Typography>
              <Stack
                direction="row"
                useFlexGap
                sx={{ flexWrap: "wrap", gap: 0.5 }}
              >
                {e?.stack[0]?.split(", ").map((s, index) => (
                  <Chip
                    key={index}
                    label={s}
                    sx={{
                      color: mode === "dark" ? "#5EEAD4" : "#ff5733",
                      backgroundColor:
                        mode === "dark"
                          ? "rgba(26, 199, 173, 0.2)"
                          : "rgba(255, 190, 173, 0.2)",
                      fontWeight: 500,
                    }}
                  />
                ))}
              </Stack>
              <Stack
                spacing={{ xs: 0.5, sm: 0 }}
                direction="row"
                useFlexGap
                sx={{
                  flexWrap: "wrap",
                  ":hover": {
                    color: mode === "dark" ? "#5EEAD4" : "#ff5733",
                  },
                }}
              >
                {e?.projects?.[0]?.split(", ").map((p, x) => (
                  <Typography
                    key={x}
                    variant="button"
                    component="span"
                    sx={{
                      color: mode === "dark" ? "#ddd" : "#3d3d3d",
                      backgroundColor: "transparent",
                      "&:hover": {
                        backgroundColor: "transparent",
                        color: mode === "dark" ? "#5EEAD4" : "#ff5733",
                        outline: "none",
                      },
                      "&:focus": {
                        outline: "none",
                        backgroundColor: "transparent",
                        color: mode === "dark" ? "#5EEAD4" : "#ff5733",
                      },
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <SvgIcon
                      sx={{ width: "14px", marginRight: 0.5, marginLeft: 1.5 }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="size-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M13.19 8.688a4.5 4.5 0 0 1 1.242 7.244l-4.5 4.5a4.5 4.5 0 0 1-6.364-6.364l1.757-1.757m13.35-.622 1.757-1.757a4.5 4.5 0 0 0-6.364-6.364l-4.5 4.5a4.5 4.5 0 0 0 1.242 7.244"
                        />
                      </svg>
                    </SvgIcon>
                    {p}
                  </Typography>
                ))}
              </Stack>
            </Stack>
          </Stack>
        </CardActionArea>
      </Card>
    </motion.div>
  )
})

export default function Experience({
  setSelectedExperience,
  selectedExperience,
  mode,
}) {
  const [focusedCard, setFocusedCard] = useState(null)

  return (
    <SlideIn direction="right">
      <Typography
        variant="h6"
        sx={{
          fontWeight: 700,
          textTransform: "uppercase",
          color: mode === "dark" ? "#fff" : "#000",
          display: { lg: "none", xs: "block" },
          paddingBottom: 6,
        }}
      >
        Experience
      </Typography>
      <Stack direction="column" spacing={2} paddingX={2} paddingY={1}>
        <AnimatePresence>
          {experience.map((e, i) => (
            <ExperienceCard
              key={e.id}
              e={e}
              i={i}
              focusedCard={focusedCard}
              setFocusedCard={setFocusedCard}
              mode={mode}
              setSelectedExperience={setSelectedExperience}
              selectedExperience={selectedExperience}
            />
          ))}
        </AnimatePresence>
      </Stack>
    </SlideIn>
  )
}
