import React, { Suspense } from "react"
const Box = React.lazy(() => import("@mui/material/Box"))
const Chip = React.lazy(() => import("@mui/material/Chip"))
const Divider = React.lazy(() => import("@mui/material/Divider"))
const Stack = React.lazy(() => import("@mui/material/Stack"))
const Typography = React.lazy(() => import("@mui/material/Typography"))
const CustomLink = React.lazy(() => import("../utils/CustomLink"))
import { publications } from "../data/publications"
import { SlideIn } from "../utils/ExampleUsage"

export default function Publications({ mode }) {
  return (
    <SlideIn direction="right">
      <Typography
        variant="h6"
        sx={{
          fontWeight: 700,
          textTransform: "uppercase",
          color: mode === "dark" ? "#fff" : "#000",
          display: { lg: "none", xs: "block" },
          paddingBottom: 6,
        }}
      >
        Publications
      </Typography>
      <Box sx={{ flexGrow: 1 }}>
        <Stack
          spacing={1}
          sx={{
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
          direction="column"
        >
          <Suspense>
            {publications?.map((p, i) => (
              <Stack
                key={i}
                spacing={{ xs: 0.5, sm: 1 }}
                direction="column"
                useFlexGap
                sx={{ flexWrap: "wrap" }}
              >
                <Chip
                  label={p?.type}
                  sx={{
                    borderRadius: "5px",
                    width: "fit-content",
                    p: 1,
                    color: mode === "dark" ? "#5EEAD4" : "#ff5733",
                    backgroundColor:
                      mode === "dark"
                        ? "rgba(26, 199, 173, 0.2)"
                        : "rgba(255, 190, 173, 0.2)",
                  }}
                />
                <CustomLink
                  href={p?.href}
                  textWrap={"pretty"}
                  color={mode === "dark" ? "#5EEAD4" : "#ff5733"}
                  hoverColor={mode === "dark" ? "#5EEAD4" : "#ff5733"}
                  variant="h7"
                  fontWeight={600}
                >
                  {p?.title}
                </CustomLink>
                <Typography
                  variant="subtitle2"
                  sx={{
                    color: mode === "dark" ? "#94A3B8" : "#5a5e72",
                  }}
                >
                  {p?.date}
                </Typography>
                <Stack
                  direction="column"
                  spacing={1}
                  sx={{ flexWrap: "wrap", gap: 0.5, pt: 2 }}
                >
                  <Typography variant="subtitle2">
                    DOI:{" "}
                    {
                      <CustomLink
                        href={p?.href}
                        linkColor={mode === "dark" ? "#fff" : "#000"}
                        hoverColor={mode === "dark" ? "#5EEAD4" : "#ff5733"}
                        variant={"subtitle2"}
                      >
                        {p?.doi}
                      </CustomLink>
                    }
                  </Typography>
                  <Typography variant="subtitle2">
                    Conference: {p?.conference}
                  </Typography>
                </Stack>
                {i < publications.length - 1 && (
                  <Divider sx={{ mt: 1, mb: 3 }} />
                )}
              </Stack>
            ))}
          </Suspense>
        </Stack>
      </Box>
    </SlideIn>
  )
}
