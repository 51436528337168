import React from "react"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid2"
import { styled } from "@mui/material/styles"
import Paper from "@mui/material/Paper"
import Divider from "@mui/material/Divider"
import useMediaQuery from "@mui/material/useMediaQuery"

const Item = styled(Paper)(({ theme, fontWeight }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  // textAlign: "start",
  color: "#000",
  backgroundColor: "transparent",
  backgroundImage: "none",
  boxShadow: "none",
  fontWeight: fontWeight,
  textAlign: 'justify',
}))

export default function Project({ fontWeight, text1, text2, text3, color1 }) {
  const matches = useMediaQuery("(min-width:800px)")
  return (
    <Box sx={{ flexGrow: 1, width: "100%", paddingBottom: 2, textAlign: 'justify' }}>
      <Grid container spacing={1} columns={16}>
        {matches ? (
          <>
            <Grid size={4}>
              <Item fontWeight={fontWeight} sx={{color: color1, fontWeight: 600}}>{text1}</Item>
            </Grid>
            <Grid size={8}>
              <Item fontWeight={fontWeight} sx={{maxWidth: '95%'}}>{text2}</Item>
            </Grid>
            <Grid size={4}>
              <Item fontWeight={fontWeight}>{text3}</Item>
            </Grid>
          </>
        ) : (
          <>
          <Grid size={5}>
            <Item fontWeight={fontWeight}>{text3}</Item>
          </Grid>
          <Grid size={11}>
            <Item fontWeight={fontWeight}>{text2}</Item>
          </Grid>
        </>
        )}
      </Grid>
      <Divider sx={{borderColor: '#c2c2c2'}}/>
    </Box>
  )
}
