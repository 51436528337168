import React from 'react'
import { motion, useAnimation } from 'framer-motion'

// 1. Fade In Component with customizable props
export const FadeIn = ({ children, duration = 0.5, delay = 0 }) => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    transition={{ duration, delay }}
  >
    {children}
  </motion.div>
)

// 2. Slide In Component with direction, duration, and delay control
export const SlideIn = ({ children, direction = 'left', duration = 0.5, delay = 0 }) => {
  const variants = {
    hidden: {
      x: direction === 'left' ? -100 : direction === 'right' ? 100 : 0,
      y: direction === 'top' ? -100 : direction === 'bottom' ? 100 : 0,
      opacity: 0,
    },
    visible: {
      x: 0,
      y: 0,
      opacity: 1,
    },
    exit: {
      x: direction === 'left' ? 100 : direction === 'right' ? -100 : 0,
      y: direction === 'top' ? 100 : direction === 'bottom' ? -100 : 0,
      opacity: 0,
    },
  }

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      exit="exit"
      variants={variants}
      transition={{ duration, delay }}
    >
      {children}
    </motion.div>
  )
}

// 3. Keyframes Component with optional loop and duration
export const Keyframes = ({ children, loop = Infinity, duration = 2 }) => {
  const animate = {
    scale: [1, 1.5, 2, 1.5, 1],
    rotate: [0, 90, 180, 270, 360],
    borderRadius: ["0%", "50%", "50%", "20%", "0%"],
  }

  return (
    <motion.div
      animate={animate}
      transition={{ duration, repeat: loop, repeatType: "loop" }}
    >
      {children}
    </motion.div>
  )
}

// 4. Staggered List Component with customizable stagger delay
export const StaggeredList = ({ children, stagger = 0.2 }) => (
  <motion.ul
    variants={{
      hidden: { opacity: 0 },
      visible: {
        opacity: 1,
        transition: { staggerChildren: stagger },
      },
    }}
    initial="hidden"
    animate="visible"
    exit="hidden"
    style={{ listStyle: 'none', paddingLeft: 0 }}
  >
    {React.Children.map(children, (child) => (
      <motion.li
        variants={{
          hidden: { y: 20, opacity: 0 },
          visible: { y: 0, opacity: 1 },
          exit: { y: 20, opacity: 0 },
        }}
      >
        {child}
      </motion.li>
    ))}
  </motion.ul>
)

// 5. Hover Scale Component with optional scale factor and transition
export const HoverScale = ({ children, scaleFactor = 1.1, duration = 0.2 }) => (
  <motion.div
    whileHover={{ scale: scaleFactor }}
    whileTap={{ scale: 0.9 }}
    transition={{ duration }}
  >
    {children}
  </motion.div>
)

// 6. Scroll Triggered Animation with optional trigger point and threshold
export const ScrollTriggered = ({
  children,
  threshold = 0.8,
  duration = 0.5,
  delay = 0,
}) => (
  <motion.div
    initial={{ opacity: 0, y: 50 }}
    whileInView={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0, y: 50 }}
    viewport={{ once: true, amount: threshold }}
    transition={{ duration, delay }}
  >
    {children}
  </motion.div>
)

// 7. Gesture Animations Component with advanced gestures support
export const Gesture = ({ children }) => (
  <motion.div
    whileHover={{ scale: 1.1 }}
    whileTap={{ scale: 0.95, rotate: -10 }}
    whileDrag={{ scale: 1.05 }}
    drag
    dragConstraints={{ top: -50, left: -50, right: 50, bottom: 50 }}
  >
    {children}
  </motion.div>
)

// 8. Parallax Scroll Component for parallax effect on scroll
export const Parallax = ({ children, speed = 0.5 }) => {
  const [offsetY, setOffsetY] = React.useState(0);

  const handleScroll = () => {
    setOffsetY(window.pageYOffset);
  }

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <motion.div style={{ transform: `translateY(${offsetY * speed}px)` }}>
      {children}
    </motion.div>
  );
}

// 9. Page Transition Component for full-page transitions
export const PageTransition = ({ children }) => {
  const animationControls = useAnimation();

  return (
    <motion.div
      initial={{ opacity: 0, x: -100 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: 100 }}
      transition={{ duration: 0.75, ease: "easeInOut" }}
    >
      {children}
    </motion.div>
  );
}

// 10. Reusable Animation Variants with more flexibility
export const AnimateOnVisibility = ({ children, variants, threshold = 0.5, delay = 0 }) => (
  <motion.div
    initial="hidden"
    whileInView="visible"
    exit="exit"
    variants={variants}
    viewport={{ once: true, amount: threshold }}
    transition={{ delay }}
  >
    {children}
  </motion.div>
);
