export const Frontend_skill = [
  {
    skill_name: "Html 5",
  },
  {
    skill_name: "Css",
  },
  {
    skill_name: "JavaScript",
  },
  {
    skill_name: "Material UI",
  },
  {
    skill_name: "React",
  },
  {
    skill_name: "TypeScript",
  },
  {
    skill_name: "Framer Motion",
  },
  {
    skill_name: "EJS",
  },
]

export const Backend_skill = [
  {
    skill_name: "Node js",
  },
  {
    skill_name: "Express js",
  },
  {
    skill_name: "Mongo db",
  },
  {
    skill_name: "RabbitMQ",
  },
  {
    skill_name: "PostgreSQL",
  },
  {
    skill_name: "Graphql",
  },
  {
    skill_name: "Python",
  },
  {
    skill_name: "scikit-learn",
  },
  {
    skill_name: "Docker",
  },

  {
    skill_name: "Git",
  },
]

export const standards = [
  {
    skill_name: "Schema.org",
  },
  {
    skill_name: "HL7 FHIR",
  },
  {
    skill_name: "DICOM",
  },
  {
    skill_name: "SNOMED CT",
  },
  {
    skill_name: "LOINC",
  },
  {
    skill_name: "WCAG2.2",
  },
]

export const communication_skill = [
  { skill_name: "Verbal & Written Communication"},
  { skill_name: "Active Listening" },
  { skill_name: "Attention to Details"},
]

export const problem_solving_skill = [
  { skill_name: "Prioritization"},
  { skill_name: "Analytical Thinking"},
  { skill_name: "Creativity"},
  { skill_name: "Decision-Making"},
]

export const learning_skill = [
  { skill_name: "Lifelong Learning"},
  { skill_name: "Adaptability"},
  { skill_name: "Resilience"},
]

export const language_skills = [
  { skill_name: "Arabic", level: "Native"},
  { skill_name: "English", level: "Fluent (C1)"},
  { skill_name: "German", level: "Intermediate (B2)"},
]
