export const experience = [
  {
    id: 1,
    start: "Jan 2022",
    end: "PRESENT",
    title: "Researcher",
    Department: "Digital Health",
    Company: "Fraunhofer FIT",
    location: "Sankt Augustin - Germany",
    stack: [
      "JavaScript, React, Node.js, HTML & CSS, PostgreSQL, GraphQL, REST, Fastify, HL7 FHIR, Python, scikit-learn, Docker, Git, WCAG2.2, Schema.org",
    ],
    shortDescription:
      "Build and maintained e-health web applications and services. Building Machine Learning components. using pre-trained and fine tuned large language models in web applications.",
    longDescription: `Since joining Fraunhofer FIT's Digital Health Department in 2022 as a researcher, I have been actively involved in a wide range of projects that have solidified my expertise in health informatics and e-health development. My work has spanned the development and implementation of medical standards, health informatics core concepts, and a strong focus on patient engagement, empowerment, and accessibility, which are critical in advancing digital health solutions.\n\n
    In particular, I have played a key role in the design and execution of ETL processes, enabling efficient data integration and transformation for clinical systems. I have also been deeply involved in the development of web-based applications and clinical dashboards, delivering data-driven insights to support healthcare professionals. My contributions extend to the realm of machine learning and artificial intelligence, where I have applied these technologies to enhance predictive analytics and patient outcomes.\n\n
    Moreover, I have led efforts in accessibility evaluation, ensuring that digital health platforms are inclusive and usable by individuals with varying abilities. My technical expertise covers comprehensive data modeling, API development—both REST and GraphQL—and the design of complex data schemas and process flow charts, ensuring seamless interoperability and efficient workflow integration.
    I have actively participated in user requirements engineering, collaborating closely with healthcare providers, technical teams, and other stakeholders to ensure the alignment of digital health solutions with real-world needs. My work has included consulting and providing expert advice on optimizing system usability and compliance with regulatory frameworks.\n\n
    Additionally, I have contributed to scientific research through publications in prominent peer-reviewed journals and have presented my findings at multiple conferences. This academic and practical blend of experience underscores my ability to contribute at the intersection of technology, health informatics, and patient-centered care, driving innovation in the digital health landscape.`,
    link: "https://www.fit.fraunhofer.de/",
    projects: [
      "ESCAPE, INGE, FrühstArt, CAREPATH, genomDE, Accessibility Evaluation",
    ],
    projectsDetails: [
      {
        project: "ESCAPE",
        technology: ["Node.js", "JavaScript", "REST", "HL7 FHIR", "Git"],
        link: "https://escape-project.org/",
        keyContributions: [
          "Contributed to building an ETL process for secure and interoperable caching metadata and clinical data.",
          "Handled data integration from six European sites.",
          "Ensured data storage compliance with FHIR HL7 standards.",
        ],
        projectOverview: [
          "Create the blended-collaborative care (BCC) approach - an integrated, holistic, biopsychosocial, patient-centred intervention",
          "Aim to better coordinate GP appointments and drug interactions, and reduce hospitalisations to improve the physical and mental health of elderly patients",
          "Utilise e-Health technologies and multimorbidity data to create holistic patient-centred treatments across five European countries and languages",
          "Communicate and disseminate the project results to policy makers and other relevant stakeholders to initiate the implementation of ESCAPE results in routine care practices for elderly multimorbid patients in Europe",
          "Employ Care Managers to liaise between the patient and their specialist team, reducing the care burden faced by informal carers (i.e., spouses and children)",
        ],
      },
      {
        project: "INGE",
        technology: ["Python", "REST", "HL7 FHIR", "scikit-learn", "Plotly"],
        link: "https://www.gewi-institut.de/projects/inge-integrate4care__trashed/",
        keyContributions: [
          "Conducted analysis of questionnaire responses.",
          "Processed data for pattern recognition and data science tasks.",
          "Contributed to machine learning model development.",
          "Supported the creation of early warning systems and clinical decision support systems.",
        ],
        projectOverview: [
          "Support Cross-Sector Care: Develop a digital service platform to improve communication and knowledge transfer between caregivers and care providers.",
          "Enhance Home Care: Facilitate continuous and sustainable care planning for individuals cared for at home by relatives.",
          "Counselling Instruments: Create IT-supported tools for mandatory care counselling visits, enhancing assessment and planning.",
          "Early Prevention: Integrate machine learning to detect and prevent severe regressions in patient care through early alerts.",
        ],
      },
      {
        project: "FrühstArt",
        technology: [
          "React",
          "HTML & CSS",
          "JavaScript",
          "Node.js",
          "HL7 FHIR",
          "REST",
          "GraphQL",
          "Git",
        ],
        link: "https://fruehstart.uni-koeln.de/",
        keyContributions: [
          "Contributed to gathering user requirements for a platform.",
          "Helped develop a framework based on a rule-based architecture model.",
          "Built progressive web applications with a real-time messaging system.",
          "Contributed to the data model following HL7 FHIR standards.",
          "Assisted in application maintenance.",
        ],
        projectOverview: ["Address Childhood Obesity: Targets overweight children aged 3-6 to prevent future health issues.", "Lifestyle Changes: Coaches visit families to promote physical activity, healthy eating, better sleep, and reduced media consumption.", "Support and Tools: Provides an app for parents and integrates local support and rehabilitation services.", "Inclusive Approach: Offers services in German and Turkish to reach families with migration backgrounds.", "Long-term Evaluation: Monitors participating children for a year and compares health outcomes with a control group."],
      },
      {
        project: "CAREPATH",
        technology: ["React", "JavaScript", "HL7 FHIR", "REST"],
        link: "https://www.carepath.care/",
        keyContributions: [
          "Contributed to building a clinical decision support system.",
          "Participated in a usability study.",
          "Contributed in the development of an automated drug-drug interaction warning system.",
        ],
        projectOverview: ["Improve Patient Safety: Develop a clinical decision support system to prevent drug-drug interactions.", "Enhance Usability: Conduct a usability study to ensure the system is user-friendly and efficient.", "Automated Warnings: Implement an automated system to alert healthcare providers to potential drug interactions.", "Real-time Monitoring: Provide real-time monitoring of patient data to prevent adverse drug events.", "Interoperability: Ensure the system is compatible with existing healthcare systems and standards."],
      },
      {
        project: "genomDE",
        technology: [
          "React",
          "HTML & CSS",
          "JavaScript",
          "HL7 FHIR",
          "REST",
          "GraphQL",
          "Schema.org",
        ],
        link: "https://www.genom.de/de",
        keyContributions: [
          "Contributed to the validation of clinical data entry.",
        ],
        projectOverview: ["Improve Clinical Applications: Enhance patient access to genomic sequencing in routine care for better diagnosis, treatment, and prevention.", "Strengthen Biomedical Research: Develop a knowledge-generating care concept with a focus on data protection and harmonization.", "Optimize Data Infrastructure: Create a practical data infrastructure and comprehensive information management for patients and their families."]
      },
      {
        project: "Accessibility Evaluation",
        technology: ["WCAG2.2", "Jira"],
        link: "https://www.fit.fraunhofer.de/en/business-areas/digital-health/projects/wai-act.html",
        keyContributions: [
          "Contributed to in-depth evaluation of website web accessibility.",
          "Generated comprehensive reports of accessibility failures and passes.",
          "Provided recommendations for possible fixes.",
          "Followed structure and process flows set by European web accessibility experts from Fraunhofer FIT, Digital Health department.",
        ],
        projectOverview: ["Improve Web Accessibility: Evaluate the accessibility of websites and web applications to ensure compliance with WCAG 2.2 standards.", "Comprehensive Reports: Generate detailed reports of accessibility failures and passes, providing recommendations for improvements.", "Expert Recommendations: Follow the structure and process flows set by European web accessibility experts to ensure high-quality evaluations.", "User-Centered Design: Ensure that websites are accessible to all users, including those with disabilities, by following best practices and guidelines."]
      },
    ],
    logo: "./fit.jpg",
  },
  {
    id: 2,
    start: "Feb 2020",
    end: "Dec 2021",
    title: "Project Engineer and Product Owner",
    Department: "Software and Business Unit",
    Company: "Conbility GmbH",
    location: "Aachen - Germany",
    stack: [
      "JavaScript, EJS, Node.js, HTML & CSS, MongoDB, REST, express, Microsoft Azure, Git, .NET",
    ],
    shortDescription:
      "Worked on different projects since I started with Conbility in February 2020. In software development, I participated in developing desktop applications based on .NET framework, mainly writing front-end components and successfully led the development of web applications using the MEN Stack.",
    longDescription: `After completing my master's in 2020, I joined Conbility GmbH, where I had the opportunity to gain practical experience in both business and technology by working on several innovative projects. One of the key projects I contributed to was KMUSense 4.0, where I was involved in the development and data processing of minimally invasive sensors, helping to advance the sensor technology landscape. Additionally, I played a critical role in enhancing the OPLYSIS cost analysis software, where I was responsible for adding sustainability calculations and analytical features that improved the software's capabilities in cost evaluation and environmental impact analysis.\n\nAnother significant milestone in my professional growth was leading the development of the web-based networking and event-hosting platform meet2connect. This experience provided me with a deep understanding of web development and was instrumental in shaping my expertise in building scalable web solutions. Beyond technical development, I was actively involved in consulting projects, particularly in business development and sustainability evaluations, where I helped clients navigate complex sustainability challenges.\n\nIn addition to my technical contributions, I had the opportunity to contribute to the technical documentation of large industrial machines, including a Tape Placement Machine, and I played a pivotal role in writing final reports and project documentation. These experiences not only enhanced my technical writing skills but also deepened my understanding of industrial manufacturing processes. My time at Conbility was foundational in bridging the gap between business needs and technical solutions, giving me a well-rounded skill set in both development and consulting within industrial and sustainability-focused projects.`,
    link: "https://www.conbility.de/en/",
    projects: [
      "KMUsense 4.0, meet2connect, OPLYSIS, Technology Evaluation Consulting Projects",
    ],
    projectsDetails: [
      {
        project: "KMUsense 4.0",
        technology: ["MATLAB", "C#", "Simulink"],
        link: "https://www.conbility.de/2017/06/24/kmusense-4-0-produktivitaetssteigerung-durch-verbesserte-nutzung-von-produktionsdaten/",
        keyContributions: [
          "Analyzed minimally invasive sensor data to provide insights into process efficiencies and identify potential issues.",
        ],
        projectOverview: ["Improve Production Efficiency: Analyze sensor data to identify process inefficiencies and improve productivity.", "Enhance Data Insights: Provide actionable insights to optimize production processes and reduce waste.", "Increase Process Transparency: Monitor and analyze sensor data to improve process visibility and control.", "Optimize Production Quality: Use sensor data to identify quality issues and improve product quality."]
      },
      {
        project: "meet2connect",
        technology: [
          "JavaScript",
          "Node.js",
          "express",
          "REST",
          "HTML & CSS",
          "EJS",
          "MongoDB",
          "Microsoft Azure",
          "Git",
        ],
        link: "https://www.automobil-events.de/2021/04/27/meet2connect-ermoeglicht-persoenliche-gespraeche-bei-digital-events/",
        keyContributions: [
          "Built a full-stack web application for events management and hosting.",
          "Contributed to user requirements engineering and UX design.",
          "Developed database architecture and integrated video conferencing tools.",
          "Managed negotiations, marketing, and sales processes.",
        ],
        projectOverview: ["Enhance Networking: Create a platform for event attendees to connect and network with each other.", "Facilitate Event Hosting: Provide a digital solution for hosting events and managing attendees.", "Improve User Experience: Develop a user-friendly interface for seamless event participation and networking.", "Integrate Video Conferencing: Incorporate video conferencing tools to enable real-time communication and collaboration.", "Streamline Event Management: Simplify event planning, marketing, and sales processes for organizers."]
      },
      {
        project: "OPLYSIS",
        technology: ["C#", "WPF", ".NET"],
        link: "https://costing-software.com/",
        keyContributions: [
          "Contributed to adding sustainability calculations and carbon footprint assessments for industrial processes.",
          "Enhanced front-end features and participated in toolbox design.",
        ],
        projectOverview: ["Improve Technology Evaluation: Provide consulting services for technology evaluation, focusing on cost, sustainability, and environmental impact.", "Enhance Decision-Making: Support clients in making informed decisions about technology investments and sustainability initiatives.", "Optimize Resource Allocation: Help clients optimize resource allocation and reduce costs through technology assessments.", "Increase Sustainability: Promote sustainable practices and reduce environmental impact through technology evaluation and consulting."]
      },
      {
        project: "Technology Evaluation Consulting Projects",
        technology: ["OPLYSIS"],
        link: "https://www.conbility.de/en/technology-assessment",
        keyContributions: [
          "Contributed to consulting projects for technology evaluation, focusing on cost, carbon footprint, and emissions footprint using the in-house tools 'OPLYSIS' and 'OPLYSIS-eco.'",
        ],
        projectOverview: ["Using in-house software OPLYSIS for technology evaluation, cost analysis, and sustainability assessment.", "Providing consulting services for technology evaluation, focusing on cost, carbon footprint, and emissions footprint.", "Supporting clients in making informed decisions about technology investments and sustainability initiatives.", "Helping clients optimize resource allocation and reduce costs through technology assessments.", "Promoting sustainable practices and reducing environmental impact through technology evaluation and consulting."]
      },
    ],
    logo: "./cicon.png",
  },
  {
    id: 3,
    start: "May 2019",
    end: "Jan 2020",
    title: "Research Student Assistant",
    Department: "Software and Business Unit",
    Company: "AZL Aachen GmbH",
    location: "Aachen - Germany",
    stack: ["MATLAB, Simulink, C#"],
    shortDescription:
      "Processing and analyzing various sensor data using MATLAB, Simulink and C#. Developing various concepts for wireless data acquisition technology.",
    longDescription: `During my time working on my master's thesis at Fraunhofer IPT in Aachen, I also took on a student assistant role (HiWi) at AZL-Aachen GmbH, where I gained invaluable hands-on experience in the field of sensor data analysis and machine systems. My primary responsibilities involved collecting, analyzing and visualizing sensor data collected from various industrial machines, such as injection molding systems, using tools like MATLAB, Simulink, and C#. This position provided me with my first exposure to the C# programming language, allowing me to develop key skills in software development within an engineering context.\n\n Collaborating closely with highly experienced engineers, I gained deep insights into sensor technologies and their critical role in industrial processes. This experience not only sharpened my technical expertise but also provided a solid foundation for working with sensor data, including the complexities of data collection, processing, and analysis. It marked the beginning of my journey into data-driven solutions and equipped me with a strong understanding of the challenges and best practices involved in working with industrial sensor systems.`,
    link: "https://azl-aachen-gmbh.de/",
    projects: ["KMUsense 4.0"],
    projectsDetails: [
      {
        project: "KMUsense 4.0",
        technology: ["MATLAB", "C#", "Simulink"],
        link: "https://www.conbility.de/2017/06/24/kmusense-4-0-produktivitaetssteigerung-durch-verbesserte-nutzung-von-produktionsdaten/",
        keyContributions: [
          "Analyzed minimally invasive sensor data to provide insights into process efficiencies and identify potential issues.",
        ],
        projectOverview: ["Improve Production Efficiency: Analyze sensor data to identify process inefficiencies and improve productivity.", "Enhance Data Insights: Provide actionable insights to optimize production processes and reduce waste.", "Increase Process Transparency: Monitor and analyze sensor data to improve process visibility and control.", "Optimize Production Quality: Use sensor data to identify quality issues and improve product quality."]
      },
    ],
    logo: "./iazl.png",
  },
]
