export const publications = [
  {
    title:
      "Key scenarios, Use Cases & Architecture of an E-health Homecare Instance",
    type: "Conference Paper",
    date: "May 2023",
    doi: "10.1145/3563137.3563160",
    conference:
      "DSAI 2022: 10th International Conference on Software Development and Technologies for Enhancing Accessibility and Fighting Info-exclusion",
      href: "https://dl.acm.org/doi/10.1145/3563137.3563160"
  },
  {
    title:
      "A step forward in supporting home care more effectively: Individually tailored in-home care consultancy utilizing machine learning",
    type: "Conference Paper",
    date: "May 2023",
    doi: "10.1145/3563137.3563159",
    conference:
      "DSAI 2022: 10th International Conference on Software Development and Technologies for Enhancing Accessibility and Fighting Info-exclusion",
      href: "https://dl.acm.org/doi/10.1145/3563137.3563159"
  },
  {
    title:
      "Development of a flexible and interoperable architecture to customize clinical solutions targeting the care of multimorbid patients",
    type: "Conference Paper",
    date: "May 2023",
    doi: "10.1145/3563137.3563157",
    conference:
      "DSAI 2022: 10th International Conference on Software Development and Technologies for Enhancing Accessibility and Fighting Info-exclusion",
      href: "https://dl.acm.org/doi/10.1145/3563137.3563157"
  },
  {
    title:
      "Predicting and Understanding Care Levels of Elderly People with Machine Learning",
    type: "Conference Paper",
    date: "Dec 2023",
    doi: "10.1007/978-3-031-48041-6_4",
    conference: "International Conference on Human-Computer Interaction",
    href: "https://link.springer.com/chapter/10.1007/978-3-031-48041-6_4"
  },
]
