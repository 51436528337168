import React from "react"
import Typography from "@mui/material/Typography"
import CustomLink from "./CustomLink"
import { getDomain } from "./helper"

export const ProjectLink = React.memo(({ project, link, mode, matches }) => {
    if (!matches) {
      return (
        <>
          <Typography
            variant="caption"
            sx={{
              color: mode === "dark" ? "#107e6e" : "#ff5733",
              fontWeight: 700,
            }}
          >
            {project}
          </Typography>
          <br />
          <CustomLink
            href={link}
            underline="none"
            hoverColor={mode === "dark" ? "#107e6e" : "#ff5733"}
            linkColor={mode === "dark" ? "#1f1f1f" : "#1f1f1f"}
          >
            {getDomain(link)}
          </CustomLink>
        </>
      )
    }
  
    return (
      <CustomLink
        href={link}
        underline="none"
        hoverColor={mode === "dark" ? "#107e6e" : "#ff5733"}
        linkColor={mode === "dark" ? "#1f1f1f" : "#1f1f1f"}
      >
        {getDomain(link)}
      </CustomLink>
    )
  })
  ProjectLink.displayName = 'ProjectLink'